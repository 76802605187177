import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/system';
import Container from '@mui/material/Container';
import { Card, Grid, Typography } from '@mui/material';

import { usePaths } from 'src/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import noImage from 'src/assets/images/product.png';
import { fetchAllPages } from 'src/server/slice/webPagesSlice';
import { getWebPageState } from 'src/server/selectors/selector';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';
import { useSettingsContext } from 'src/components/settings';

import { WebPageItemSkeleton } from './webPages-skeleton';

export default function WebPagesListView() {
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  // const getText = useLocalizedText();
  const paths = usePaths();

  const mdDown = useResponsive('down', 'md');
  const { isMobile, screenWidth } = useScreenWidth();

  const { pages, loading } = useSelector(getWebPageState);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: pages?.total || 20,
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchAllPages(credentials));
  }, [pages?.total, dispatch]);

  const pageData = pages?.pages;

  const renderSkeleton = (
    <Grid container spacing={2} sx={{ p: 2 }}>
      {[...Array(16)].map((_, index) => (
        <Grid item key={index} xs={4} sm={3} md={3} lg={1.5}>
          <WebPageItemSkeleton />
        </Grid>
      ))}
    </Grid>
  );

  const renderList = (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mb: 15,
        my: isMobile ? 2 : 4,
      }}
    >
      <Grid
        container
        spacing={2}
        // justifyContent="center"
      >
        {pageData?.map((item, key) => {
          const pageName = item?.title?.english.replace(/ /g, '-');
          const pageUrl = item?.url;
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const translatedName = useTranslatedText(item?.title);

          return (
            <Grid item key={key} xs={4} sm={3} md={3} lg={1.5} xl={1.5}>
              <OspLink
                sx={{ '&:hover': { textDecoration: 'none' } }}
                key={paths.public.pageView(pageUrl || pageName)}
                href={`${paths.public.pageView(pageUrl || pageName)}`}
                state={{
                  pageId: item?.uid,
                }}
              >
                <Card
                  sx={{
                    p: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: 2,
                    cursor: 'pointer',
                    aspectRatio: '1 / 1',
                    width: '100%',
                    boxShadow: 'none',
                    border: '1px solid #E5E5E5',
                  }}
                >
                  <PageImages item={item} />
                </Card>
                <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'center' }}>
                  {translatedName}
                </Typography>
              </OspLink>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );

  const getMarginTop = () => {
    if (isMobile) return 0;
    if (screenWidth < 900) return 18;
    if (screenWidth < 1200) return 8;
    return 0;
  };

  return (
    <>
      <Box mt={getMarginTop()} py={4} className={`custom-text-brand-div ${mdDown ? 'mt-55' : ''}`}>
        <Box
          className="text-brand-div relative text-center d-flex flex-column justify-content-center align-items-center"
          px={mdDown ? 4 : 5}
          py={mdDown ? 3 : 5}
          my={4}
          gap={mdDown ? 2 : 4}
          borderRadius={1}
          boxShadow={3}
          sx={{ backdropFilter: 'blur(10px)' }}
        >
          <Typography
            className="custom-brand-text "
            variant="h1"
            sx={{
              lineHeight: 'normal',
              color: 'black',
              fontSize: { xs: '20px', md: '35px', lg: '50px' },
            }}
          >
            Find Pages
          </Typography>
          <Typography
            variant="h2"
            className={`${mdDown ? 'font-size-10' : 'font-size-12'}`}
            sx={{
              color: 'black',
              fontSize: { xs: '10px', md: '12px' },
              fontWeight: 400,
            }}
          >
            Over {pages?.total} Pages all over the world
          </Typography>
        </Box>
      </Box>

      {loading ? renderSkeleton : renderList}
    </>
  );
}
const PageImages = ({ item }) => {
  const PageImage = useImageTransformation(
    item?.images?.length > 0 ? item?.images[0]?.url : noImage,
    'w_500'
  );
  const PageAltText = useTranslatedText(item?.images[0]?.alt_text);
  const TranslatedText = useTranslatedText(item?.name);
  const { isMobile } = useScreenWidth();

  return item?.images[0]?.url ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        // aspectRatio: '1 / 1',
        overflow: 'hidden',
      }}
    >
      <Image
        src={PageImage || noImage}
        alt={PageAltText}
        style={{
          objectFit: 'contain',
          maxWidth: '100%',
          aspectRatio: 1 / 1,
        }}
      />
    </Box>
  ) : (
    <TextMaxLine
      variant={isMobile ? 'body2' : 'subtitle2'}
      line={2}
      sx={{ fontWeight: 'fontWeightSemiBold' }}
    >
      {TranslatedText}
    </TextMaxLine>
  );
};

PageImages.propTypes = {
  item: PropTypes.object,
};
