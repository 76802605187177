import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { get, post } from '../http';

export const fetchAllPages = createAsyncThunk(
  'pages/fetchAllPages',
  async ({ state, limit }, { rejectWithValue }) => {
    try {
      const response = await post(`/page/user/all?page=1&limit=${limit || 10}`, state);

      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const fetchAllPageSingleView = createAsyncThunk(
  'pages/single',
  async ({ pageTitle, state }, { rejectWithValue }) => {
    try {
      const response = await get(`/page/user/single/${pageTitle}`, state);

      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const fetchAllSectionForPage = createAsyncThunk(
  'pages/section',
  async ({ pageId, page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/section/user/page/${pageId}?page=${page}&limit=${limit}&search=${search || ''}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

const webPagesSlice = createSlice({
  name: 'webPages',
  initialState: {
    loading: false,
    error: null,
    pages: [],
    singlePage: {},
    sections: [],
    cache: {},
  },
  reducers: {
    clearPageState: (state) => {
      state.pages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPages.fulfilled, (state, action) => {
        state.loading = false;
        state.pages = action.payload;

        state.error = null;
      })
      .addCase(fetchAllPages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchAllPageSingleView.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPageSingleView.fulfilled, (state, action) => {
        state.loading = false;
        state.singlePage = action.payload;

        state.error = null;
      })
      .addCase(fetchAllPageSingleView.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchAllSectionForPage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllSectionForPage.fulfilled, (state, action) => {
        state.loading = false;
        state.sections = action.payload;

        state.error = null;
      })
      .addCase(fetchAllSectionForPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearPageState } = webPagesSlice.actions;

export default webPagesSlice.reducer;

// {
//   "status": 2000,
//   "message": "Success",
//   "data": {
//       "groups": [
//           {
//               "_id": "66b5b1dfab5dd91ab5a49979",
//               "images": [
//                   {
//                       "public_id": "example_public_id",
//                       "url": "example_url",
//                       "_id": "66b5b1dfab5dd91ab5a4997a"
//                   }
//               ],
//               "priority": 1,
//               "type": "PRODUCTS",
//               "layout": "HIGHLIGHT",
//               "platform": "COMMON",
//               "products": [
//                   {
//                       "_id": "66b4dc02387aa4a46360bc97",
//                       "sap_id": "12345123",
//                       "name": {
//                           "arabic": "تلفزيون Xiaomi A Pro 55 بوصة 4K UHD",
//                           "english": "Test Product with specs"
//                       },
//                       "description": {
//                           "english": "The WD 3 Premium multi-purpose vacuum cleaner is super-powerful"
//                       },
//                       "specifications": [
//                           {
//                               "_id": "66b4dc02387aa4a46360bc98"
//                           }
//                       ],
//                       "search_keywords": [],
//                       "product_labels": [],
//                       "categories": [],
//                       "brands": [],
//                       "type": "NORMAL",
//                       "order_type": "DIRECT",
//                       "is_preorder_advance_needed": false,
//                       "preorder_advance_amount": 0,
//                       "has_variants": false,
//                       "is_variant": false,
//                       "cost": 0,
//                       "price_without_tax": 46.8,
//                       "price": 53.82,
//                       "offer_price": 47.84,
//                       "offer_price_without_tax": 41.6,
//                       "tax_categories": [
//                           "1d2c299c-9693-4670-a90c-8e428178e904"
//                       ],
//                       "stock": 100,
//                       "min_order_quantity": 1,
//                       "max_order_quantity": 0,
//                       "is_return_viable": false,
//                       "is_free_delivery_product": false,
//                       "is_page_attached": false,
//                       "product_groups": [],
//                       "average_rating": 0,
//                       "review_count": 0,
//                       "is_active": true,
//                       "is_deleted": false,
//                       "uid": "d497ec0a-95ab-4a9f-bb37-ee8aedc53daf",
//                       "images": [],
//                       "createdAt": "2024-08-08T14:53:54.192Z",
//                       "updatedAt": "2024-08-08T14:53:54.192Z",
//                       "__v": 0
//                   },
//                   {
//                       "_id": "66b4dc350c86fc7f0aed0a4e",
//                       "sap_id": "123451231",
//                       "name": {
//                           "arabic": "تلفزيون Xiaomi A Pro 55 بوصة 4K UHD",
//                           "english": "Test Product with specs 2"
//                       },
//                       "description": {
//                           "english": "The WD 3 Premium multi-purpose vacuum cleaner is super-powerful"
//                       },
//                       "specifications": {
//                           "arabic": [
//                               {
//                                   "title": "Arabic Spec 1",
//                                   "value": "Arabic Spec 1 Value",
//                                   "_id": "66b4dc350c86fc7f0aed0a4f"
//                               },
//                               {
//                                   "title": "Arabic Spec 2",
//                                   "value": "Arabic Spec 2 Value",
//                                   "_id": "66b4dc350c86fc7f0aed0a50"
//                               }
//                           ],
//                           "english": [
//                               {
//                                   "title": "Spec 1",
//                                   "value": "Spec 1 Value",
//                                   "_id": "66b4dc350c86fc7f0aed0a51"
//                               },
//                               {
//                                   "title": "Spec 2",
//                                   "value": "Spec 2 Value",
//                                   "_id": "66b4dc350c86fc7f0aed0a52"
//                               }
//                           ]
//                       },
//                       "search_keywords": [],
//                       "product_labels": [],
//                       "categories": [],
//                       "brands": [],
//                       "type": "NORMAL",
//                       "order_type": "DIRECT",
//                       "is_preorder_advance_needed": false,
//                       "preorder_advance_amount": 0,
//                       "has_variants": false,
//                       "is_variant": false,
//                       "cost": 0,
//                       "price_without_tax": 46.8,
//                       "price": 53.82,
//                       "offer_price": 47.84,
//                       "offer_price_without_tax": 41.6,
//                       "tax_categories": [
//                           "1d2c299c-9693-4670-a90c-8e428178e904"
//                       ],
//                       "stock": 100,
//                       "min_order_quantity": 1,
//                       "max_order_quantity": 0,
//                       "is_return_viable": false,
//                       "is_free_delivery_product": false,
//                       "is_page_attached": false,
//                       "product_groups": [],
//                       "average_rating": 0,
//                       "review_count": 0,
//                       "is_active": true,
//                       "is_deleted": false,
//                       "uid": "9ae6a4c3-e033-4e51-a755-f436bec055f6",
//                       "images": [],
//                       "createdAt": "2024-08-08T14:54:45.596Z",
//                       "updatedAt": "2024-08-08T14:54:45.596Z",
//                       "__v": 0
//                   }
//               ],
//               "categories": [],
//               "brands": [],
//               "collections": [],
//               "product_groups": [],
//               "special_sales": [],
//               "is_active": true,
//               "is_deleted": false,
//               "uid": "94d12751-d115-4f93-83f0-12880510f8ab",
//               "createdAt": "2024-08-09T06:06:23.412Z",
//               "updatedAt": "2024-08-09T06:06:23.412Z",
//               "__v": 0
//           }
//       ],
//       "total": 1,
//       "page": 1
//   }
// }

// {
//   "status": 2000,
//   "message": "Success",
//   "data": {
//       "_id": "66b6fa3f2caa3da2ebab5f31",
//       "title": {
//           "english": "Car Accessories Page 2",
//           "arabic": "اكسسوارات السيارات"
//       },
//       "description": {
//           "english": "Car Accessories Page Desc",
//           "arabic": "اكسسوارات السيارات"
//       },
//       "product": {
//           "_id": "66b4dc02387aa4a46360bc97",
//           "sap_id": "12345123",
//           "name": {
//               "arabic": "تلفزيون Xiaomi A Pro 55 بوصة 4K UHD",
//               "english": "Test Product with specs"
//           },
//           "description": {
//               "english": "The WD 3 Premium multi-purpose vacuum cleaner is super-powerful"
//           },
//           "specifications": [
//               {
//                   "_id": "66b4dc02387aa4a46360bc98"
//               }
//           ],
//           "search_keywords": [],
//           "product_labels": [],
//           "categories": [],
//           "brands": [],
//           "type": "NORMAL",
//           "order_type": "DIRECT",
//           "is_preorder_advance_needed": false,
//           "preorder_advance_amount": 0,
//           "has_variants": false,
//           "is_variant": false,
//           "cost": 0,
//           "price_without_tax": 46.8,
//           "price": 53.82,
//           "offer_price": 47.84,
//           "offer_price_without_tax": 41.6,
//           "tax_categories": [
//               "1d2c299c-9693-4670-a90c-8e428178e904"
//           ],
//           "stock": 100,
//           "min_order_quantity": 1,
//           "max_order_quantity": 0,
//           "is_return_viable": false,
//           "is_free_delivery_product": false,
//           "is_page_attached": false,
//           "product_groups": [],
//           "average_rating": 0,
//           "review_count": 0,
//           "is_active": false,
//           "is_deleted": false,
//           "uid": "d497ec0a-95ab-4a9f-bb37-ee8aedc53daf",
//           "images": [],
//           "createdAt": "2024-08-08T14:53:54.192Z",
//           "updatedAt": "2024-08-10T06:22:00.879Z",
//           "__v": 0
//       },
//       "categories": [],
//       "brands": [],
//       "is_homepage": false,
//       "is_dynamic_product": false,
//       "is_active": true,
//       "is_deleted": false,
//       "uid": "a33606a2-9839-4f59-af64-7ad3d757184c",
//       "images": [],
//       "createdAt": "2024-08-10T05:27:27.115Z",
//       "updatedAt": "2024-08-10T05:47:35.572Z",
//       "__v": 0
//   }
// }

// {
//   "status": 2000,
//   "message": "Success",
//   "data": {
//       "sections": [
//           {
//               "_id": "66b79e89e3371f5824112aff",
//               "page": "a778191a-f26b-4e69-aa8a-d000fc00e6c1",
//               "priority": 1,
//               "title": {
//                   "arabic": "اكسسوارات السيارات",
//                   "english": "Test Page 1st"
//               },
//               "description": {
//                   "arabic": "اكسسوارات السيارات",
//                   "english": "Test Page Desc"
//               },
//               "short_contents": [
//                   {
//                       "title": {
//                           "arabic": "عنوان المحتوى القصير 1",
//                           "english": "Short Content Title 1"
//                       },
//                       "image": {
//                           "public_id": "test_publi_id",
//                           "url": "test_url"
//                       },
//                       "_id": "66b79e89e3371f5824112b00"
//                   },
//                   {
//                       "title": {
//                           "arabic": "عنوان المحتوى القصير 2",
//                           "english": "Short Content Title 2"
//                       },
//                       "image": {
//                           "public_id": "test_publi_id",
//                           "url": "test_url"
//                       },
//                       "_id": "66b79e89e3371f5824112b01"
//                   }
//               ],
//               "product_variants": [],
//               "image_position": "RIGHT",
//               "text_align": "LEFT",
//               "call_to_action": {
//                   "is_required": false,
//                   "action": "CART"
//               },
//               "layout": "HIGHLIGHT",
//               "platform": "COMMON",
//               "is_default": false,
//               "is_active": true,
//               "is_deleted": false,
//               "uid": "d7aad173-e338-42aa-9136-1f6b378323be",
//               "images": [],
//               "faq_data": [],
//               "testimonials": [],
//               "createdAt": "2024-08-10T17:08:25.148Z",
//               "updatedAt": "2024-08-10T17:08:25.148Z",
//               "__v": 0
//           },
//           {
//               "_id": "66b79e6fe3371f5824112afa",
//               "page": "a778191a-f26b-4e69-aa8a-d000fc00e6c1",
//               "title": {
//                   "arabic": "اكسسوارات السيارات",
//                   "english": "Test Page"
//               },
//               "description": {
//                   "arabic": "اكسسوارات السيارات",
//                   "english": "Test Page Desc"
//               },
//               "short_contents": [
//                   {
//                       "title": {
//                           "arabic": "عنوان المحتوى القصير 1",
//                           "english": "Short Content Title 1"
//                       },
//                       "image": {
//                           "public_id": "test_publi_id",
//                           "url": "test_url"
//                       },
//                       "_id": "66b79e6fe3371f5824112afb"
//                   },
//                   {
//                       "title": {
//                           "arabic": "عنوان المحتوى القصير 2",
//                           "english": "Short Content Title 2"
//                       },
//                       "image": {
//                           "public_id": "test_publi_id",
//                           "url": "test_url"
//                       },
//                       "_id": "66b79e6fe3371f5824112afc"
//                   }
//               ],
//               "product_variants": [],
//               "image_position": "RIGHT",
//               "text_align": "LEFT",
//               "call_to_action": {
//                   "is_required": false,
//                   "action": "CART"
//               },
//               "layout": "HIGHLIGHT",
//               "platform": "COMMON",
//               "is_default": false,
//               "is_active": true,
//               "is_deleted": false,
//               "uid": "5d331d5a-a792-4af9-b7f2-6e770ff802fc",
//               "images": [],
//               "faq_data": [],
//               "testimonials": [],
//               "createdAt": "2024-08-10T17:07:59.809Z",
//               "updatedAt": "2024-08-10T17:07:59.809Z",
//               "__v": 0
//           },
//           {
//               "_id": "66b79dcae3371f5824112af7",
//               "page": "a778191a-f26b-4e69-aa8a-d000fc00e6c1",
//               "title": {
//                   "arabic": "اكسسوارات السيارات",
//                   "english": "Test Page"
//               },
//               "description": {
//                   "arabic": "اكسسوارات السيارات",
//                   "english": "Test Page Desc"
//               },
//               "short_contents": [],
//               "product_variants": [],
//               "image_position": "RIGHT",
//               "text_align": "LEFT",
//               "call_to_action": {
//                   "is_required": false,
//                   "action": "CART"
//               },
//               "layout": "HIGHLIGHT",
//               "platform": "COMMON",
//               "is_default": false,
//               "is_active": true,
//               "is_deleted": false,
//               "uid": "14a74c97-9881-4708-91a2-b6018a6186e6",
//               "images": [],
//               "faq_data": [],
//               "testimonials": [],
//               "createdAt": "2024-08-10T17:05:14.081Z",
//               "updatedAt": "2024-08-10T17:05:14.081Z",
//               "__v": 0
//           }
//       ],
//       "total": 3,
//       "section": 1
//   }
// }
