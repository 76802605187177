/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';

import { Box, Stack, Typography } from '@mui/material';

import { usePaths } from 'src/routes/paths';

import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { colors } from 'src/utils/colors';

import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';
import TextMaxLine from 'src/components/text-max-line';

// ----------------------------------------------------------------------

export default function AllCategoryDropdownList({ data }) {
  const [isActiveLabel, setIsActiveLabel] = useState([]);
  const [subData, setSubData] = useState([]);
  const paths = usePaths();

  const handleMouseEnter = (catData, index) => {
    setIsActiveLabel((prev) => {
      const newActiveLabels = [...prev];
      newActiveLabels[index] = true;
      return newActiveLabels;
    });
    const filteredSubCategoryList = catData?.sub_categories?.filter(
      (item) => item?.main_category === catData?.uid
    );
    setSubData(filteredSubCategoryList);
  };

  const handleMouseLeave = (index) => {
    setIsActiveLabel((prev) => {
      const newActiveLabels = [...prev];
      newActiveLabels[index] = false;
      return newActiveLabels;
    });
  };

  const listContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const listItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };

  const subItemContainerVariants = {
    hidden: {
      opacity: 0,
      width: 0,
      transition: {
        duration: 0.3,
      },
    },
    visible: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  const subCategoryVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        width={250}
        sx={{ backgroundColor: 'white', borderRadius: '8px 0px 0px 8px', flexShrink: 0 }}
      >
        <m.div
          variants={listContainerVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{
            width: 250,
            backgroundColor: 'white',
            borderRadius: '8px 0px 0px 8px',
            flexShrink: 0,
          }}
        >
          {data?.map((item, key) => (
            <m.div key={key} variants={listItemVariants} style={{ marginBottom: 0 }}>
              <OspLink
                href={paths.public.categoryView(item?.name?.english?.replace(/ /g, '-'))}
                sx={{ '&:hover': { textDecoration: 'none' } }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.5}
                  position="relative"
                  sx={{
                    '&:hover': { backgroundColor: '#EAE9E9' },
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  }}
                  px={1}
                  py={0.7}
                  onMouseEnter={() => handleMouseEnter(item, key)}
                  onMouseLeave={() => handleMouseLeave(key)}
                >
                  {isActiveLabel[key] ? (
                    <Box
                      sx={{
                        backgroundColor: colors.themeSecondary,
                        borderRadius: '0px 7px 7px 0px',
                        width: 7,
                        height: 50,
                        position: 'absolute',
                        left: 0,
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <Box sx={{ flexShrink: 0 }}>
                    <CategoryLogo imageData={item?.logo} />
                  </Box>
                  <TextMaxLine
                    line={2}
                    variant="body2"
                    fontSize={15}
                    color="black"
                    sx={{ flexShrink: 0 }}
                  >
                    {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      useTranslatedText(item?.name)
                    }
                  </TextMaxLine>
                </Stack>
              </OspLink>
            </m.div>
          ))}
        </m.div>
      </Box>
      {subData?.length > 0 && (
        <AnimatePresence>
          <m.div
            variants={subItemContainerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{
              backgroundColor: '#eae9e9',
              borderRadius: '0 8px 8px 0',
              maxWidth: 600,
              flexShrink: 0,
              overflow: 'hidden',
            }}
          >
            <Box display="flex" flexWrap="wrap" gap={3} p={2}>
              {subData?.map((item, key) => (
                <Box key={key} height="fit-content" maxWidth={200}>
                  <OspLink
                    href={paths.public.categoryView(item?.name?.english?.replace(/ /g, '-'))}
                    sx={{ '&:hover': { textDecoration: 'none' } }}
                  >
                    <Typography variant="body1" color="black" fontWeight={700}>
                      {useTranslatedText(item?.name)}
                    </Typography>
                  </OspLink>
                  <AnimatePresence>
                    {item?.sub_categories?.map((subCat, i) => (
                      <m.div
                        key={i}
                        variants={subCategoryVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                      >
                        <OspLink
                          href={paths.public.categoryView(
                            subCat?.name?.english?.replace(/ /g, '-')
                          )}
                          sx={{ '&:hover': { textDecoration: 'none' } }}
                        >
                          <Typography
                            variant="body2"
                            color="black"
                            pt={0.5}
                            sx={{ textDecoration: 'underline' }}
                          >
                            {useTranslatedText(subCat?.name)}
                          </Typography>
                        </OspLink>
                      </m.div>
                    ))}
                  </AnimatePresence>
                </Box>
              ))}
            </Box>
          </m.div>
        </AnimatePresence>
      )}
    </Stack>
  );
}

AllCategoryDropdownList.propTypes = {
  data: PropTypes.any,
};

export const CategoryLogo = ({ imageData }) => {
  const transformedImage = useImageTransformation(imageData?.url, 'w_300');
  const translatedText = useTranslatedText(imageData?.alt_text);
  return (
    <>
      {imageData?.url ? (
        <Image
          alt={translatedText}
          src={transformedImage}
          height={40}
          width={40}
          sx={{
            maxWidth: 40,
            maxHeight: 40,
          }}
          imageStyle={{
            objectFit: 'contain',
            aspectRatio: '1/1',
            width: 40,
            height: 40,
          }}
        />
      ) : (
        <Image
          alt=""
          src="/assets/images/default/icon.webp"
          height={40}
          width={40}
          sx={{
            maxWidth: 40,
            maxHeight: 40,
          }}
          imageStyle={{
            objectFit: 'contain',
            aspectRatio: '1/1',
            width: 40,
            height: 40,
          }}
        />
      )}
    </>
  );
};

CategoryLogo.propTypes = {
  imageData: PropTypes.any,
};
