import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouter, usePathname } from 'src/routes/hooks';

import { localStorageGetItem } from 'src/utils/storage-available';

import { useSettingsContext } from 'src/components/settings';

import { allLangs, defaultLang } from './config-lang';

// ----------------------------------------------------------------------

export function useLocales() {
  const langStorage = localStorageGetItem('i18nextLng');

  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  return {
    allLangs,
    currentLang,
  };
}

// ----------------------------------------------------------------------

export function useTranslate() {
  const { t, i18n, ready } = useTranslation();
  const router = useRouter();

  const settings = useSettingsContext();
  const currentPathname = usePathname();

  const onChangeLang = useCallback(
    (newlang) => {
      i18n.changeLanguage(newlang);
      settings.onChangeDirectionByLang(newlang);

      // Change the route to include the language code
      const newPath =
        newlang === 'ar'
          ? `/ar${currentPathname}`
          : `${currentPathname?.replace('/ar/', '/') || '/'}`;
      router.replace(newPath);
    },
    [i18n, router, settings, currentPathname]
  );

  return {
    t,
    i18n,
    ready,
    onChangeLang,
  };
}
