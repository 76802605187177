import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const OspLink = forwardRef(({ type = 'INTERNAL', href, children, state, sx, ...props }, ref) => {
  if (type === 'INTERNAL') {
    if (typeof href === 'string') {
      return (
        <RouterLink
          to={href}
          ref={ref}
          state={state}
          style={{
            textDecoration: 'none',
            '&:hover': { textDecoration: 'none' },
            color: 'black',
            position: 'relative',
            pointerEvents: href ? 'auto' : 'none',
            ...sx,
          }}
          {...props}
        >
          {children}
        </RouterLink>
      );
    }
    return null;
  }

  if (type === 'EXTERNAL') {
    if (typeof href === 'string') {
      return (
        <a
          href={href}
          color="black"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            color: 'black',
            pointerEvents: href ? 'auto' : 'none',
          }}
        >
          {children}
        </a>
      );
    }
  }
  return null;
});

OspLink.displayName = 'OspLink';

OspLink.propTypes = {
  href: PropTypes.any.isRequired,
  children: PropTypes.any,
  sx: PropTypes.object,
  state: PropTypes.any,
  type: PropTypes.string, // EXTERNAL // INTERNAL
};

export default OspLink;
