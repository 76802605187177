import { Navigate, useRoutes } from 'react-router-dom';

import { useLocales } from 'src/locales';

import { generateRoutes, } from './routes';

export default function Router() {
  const { currentLang } = useLocales();
  const lang = currentLang?.value

  const transformedRoutes = generateRoutes(lang)

  return useRoutes([
    ...transformedRoutes.publicRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
