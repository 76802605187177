import { m } from 'framer-motion';
import { useEffect } from 'react';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';

import { usePaths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';

import { fetchSettings } from 'src/server/slice/settingsSlice';
import { getSettingsState } from 'src/server/selectors/selector';

import { varBounce, MotionContainer } from 'src/components/animate';

import { LegalSkeleton } from './legal-skeleton';

// ----------------------------------------------------------------------

export default function LegalPage() {
  const dispatch = useDispatch();
  const { legalSettings } = useSelector(getSettingsState);
  const pathname = usePathname();
  const paths = usePaths();

  useEffect(() => {
    if (!legalSettings?.uid) {
      dispatch(fetchSettings({ type: 'legal' }));
    }
  }, [dispatch, legalSettings?.uid]);

  if (legalSettings) {
    switch (pathname) {
      case paths.legal.termsAndConditions:
        return (
          <Stack sx={{ p: 4 }}>
            <MotionContainer>
              {/* <m.div variants={varBounce().inLeft}>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  Terms and Conditions
                </Typography>
              </m.div> */}

              <m.div variants={varBounce().inLeft}>
                {legalSettings?.legal?.terms && (
                  <Typography sx={{ color: 'text.secondary' }}>
                    {parse(legalSettings?.legal?.terms)}
                  </Typography>
                )}
              </m.div>
            </MotionContainer>
          </Stack>
        );
      case paths.legal.shippingPolicy:
        return (
          <Stack sx={{ p: 4 }}>
            <MotionContainer>
              {/* <m.div variants={varBounce().inLeft}>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  Shipping policy
                </Typography>
              </m.div> */}

              <m.div variants={varBounce().inLeft}>
                {legalSettings?.legal?.shipping_policy && (
                  <Typography sx={{ color: 'text.secondary' }}>
                    {parse(legalSettings?.legal?.shipping_policy)}
                  </Typography>
                )}
              </m.div>
            </MotionContainer>
          </Stack>
        );
      case paths.legal.privacyPolicy:
        return (
          <Stack sx={{ p: 4 }}>
            <MotionContainer>
              {/* <m.div variants={varBounce().inLeft}>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  Privacy policy
                </Typography>
              </m.div> */}

              <m.div variants={varBounce().inLeft}>
                {legalSettings?.legal?.privacy_policy && (
                  <Typography sx={{ color: 'text.secondary' }}>
                    {parse(legalSettings?.legal?.privacy_policy)}
                  </Typography>
                )}
              </m.div>
            </MotionContainer>
          </Stack>
        );
      case paths.legal.faq:
        return (
          <Stack sx={{ p: 4 }}>
            <MotionContainer>
              <m.div variants={varBounce().inLeft}>
                {legalSettings?.legal?.faq_data && (
                  <Typography sx={{ color: 'text.secondary' }}>
                    {parse(legalSettings?.legal?.faq_data)}
                  </Typography>
                )}
              </m.div>
            </MotionContainer>
          </Stack>
        );

      default:
        return (
          <Stack sx={{ p: 4 }}>
            <LegalSkeleton />
          </Stack>
        );
    }
  }
  return (
    <Stack sx={{ p: 4 }}>
      <LegalSkeleton />
    </Stack>
  );
}
