import { createSelector } from 'reselect';

// Input selector
const selectProductDetails = (state) => state.product;
const selectBrand = (state) => state.brand;
const selectWebPages = (state) => state.pages;
const selectSpecialSale = (state) => state.specialSale;

const selectCoupon = (state) => state.order.coupon;
const selectCategories = (state) => state.categories;
const selectAuth = (state) => state.auth;
const selectOrderSingleView = (state) => state.order.orderSingleView;
const selectProfile = (state) => state.profile;
const selectProduct = (state) => state.product;
const selectGroup = (state) => state.group;
const selectBanners = (state) => state.banners;
const selectCart = (state) => state.order.cart;
const selectOrder = (state) => state.order;
const selectCurrency = (state) => state.settings.currency;
const selectSettings = (state) => state.settings;
const selectCollection = (state) => state.collections;
const selectReview = (state) => state.reviews;

// Memoized selector with transformation logic
export const getProductDetailsSate = createSelector([selectProductDetails], (product) => ({
  product: product?.productDetails,
  detailLoading: product?.detailLoading,
  variantLoading: product?.variantLoading,
  variantSummery: product?.variantSummery,
  mainProductDetails: product?.mainProductDetails,
}));

export const getCartState = createSelector([selectCart], (cart) => ({
  cart,
}));

export const getBrandState = createSelector([selectBrand], (brand) => ({
  brands: brand.brands,
  loading: brand.loading,
  brandSingleView: brand.brandSingleView,
  brandsByHierarchy: brand.brandsByHierarchy,
}));
export const getSpecialSaleState = createSelector([selectSpecialSale], (specialSale) => ({
  specialSales: specialSale.specialSales,
  loading: specialSale.loading,
  specialSaleSingleView: specialSale.specialSaleSingleView,
  specialSaleSingleViewList: specialSale.specialSaleSingleViewList,
  brandsByHierarchy: specialSale.brandsByHierarchy,
  products: specialSale.products,
}));
export const getWebPageState = createSelector([selectWebPages], (pages) => ({
  pages: pages.pages,
  loading: pages.loading,
  singlePage: pages.singlePage,
  sections: pages.sections,
}));

export const getCouponState = createSelector([selectCoupon], (couponState) => ({
  isValidCoupon: couponState.isValidCoupon,
}));

export const getCategoriesState = createSelector([selectCategories], (categoryState) => ({
  categoriesByHierarchy: categoryState.categoriesByHierarchy,
  categoriesByHierarchyHome: categoryState.categoriesByHierarchyHome,
  categories: categoryState.categories,
  loading: categoryState.loading,
  categoryDetails: categoryState.categoryDetails,
}));

export const getAuthState = createSelector([selectAuth], (auth) => ({
  loginData: auth.loginData,
  loading: auth.loading,
  guestUserLoading: auth.guestUserLoading,
  otpData: auth.otpData,
  loginPopup: auth.loginPopup,
}));

export const getOrderSingleViewState = createSelector(
  [selectOrderSingleView],
  (orderSingleDetails) => ({
    orderSingleView: orderSingleDetails,
  })
);

export const getProfileState = createSelector([selectProfile], (profile) => ({
  user: profile.user,
  logoutLoading: profile.logoutLoading,
  address: profile.address,
  createdNewAddress: profile.createdNewAddress,
  loading: profile.loading,
  createAddressLoading: profile.createAddressLoading,
  addressDetail: profile.addressDetail,
  wishListItems: profile.wishListItems,
  isWishList: profile.isWishList,
}));

export const getProductState = createSelector([selectProduct], (product) => ({
  productFilterObject: product.productFilterObject,
  products: product.products,
  loading: product.loading,
  brandProduct: product.brandProducts,
  brandProductLoading: product.brandProductLoading,
  relatedProduct: product.relatedProduct,
  productGroups: product.productGroups,
  checkProductPurchaseStatus: product.checkProductPurchaseStatus,
  searchedProduct: product.searchedProduct,
  categoryProducts: product.categoryProducts,
  categoryProdLoading: product.categoryProdLoading,
}));

export const getGroupState = createSelector([selectGroup], (group) => ({
  groups: group.groups,
  loading: group.loading,
}));

export const getBannerState = createSelector([selectBanners], (banner) => ({
  banners: banner.banners,
  loading: banner.loading,
}));

export const getCurrencyState = createSelector([selectCurrency], (currency) => ({
  currency,
}));

export const getSettingsState = createSelector([selectSettings], (settings) => ({
  basicSettings: settings.basicSettings,
  paymentSettings: settings.paymentSettings,
  legalSettings: settings.legalSettings,
  shippingSettings: settings.shippingSettings,
  pickupLocations: settings.pickupLocations,
  states: settings.states,
  country: settings.country,
  district: settings.district,
  SEOData: settings.SEOData,
  accountTab: settings.accountTab,
  loading: settings.loading,
}));

export const getOrderState = createSelector([selectOrder], (order) => ({
  cartUpdateLoading: order.cartUpdateLoading,
  loading: order.loading,
  couponLoading: order.couponLoading,
  orders: order.orders,
  isValidCoupon: order.coupon,
  resetCouponData: order.resetCoupon,
  localState: order.localState,
  bulkLoading: order.bulkLoading,
  orderStatusLoading: order.orderStatusLoading,
  onlineOrderData: order?.onlineOrderData,
}));

export const getCollectionState = createSelector([selectCollection], (collections) => ({
  collections: collections.collections,
  loading: collections.loading,
  collectionDetails: collections.collectionDetails,
}));

export const getReviewState = createSelector([selectReview], (review) => ({
  reviews: review.reviews,
  loading: review.loading,
  deleteReviewLoading: review.deleteReviewLoading,
}));
