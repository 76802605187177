import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Box, Stack } from '@mui/system';
import Container from '@mui/material/Container';
import { Grid, Divider, Typography } from '@mui/material';

import useCountdown from 'src/hooks/use-coundown';
import { useScreenWidth } from 'src/hooks/useScreenWidth';
import useLocalizedText from 'src/hooks/useLocalizedText';
import useTranslatedText from 'src/hooks/useTranslatedText';
import useImageTransformation from 'src/hooks/useImageTransformation';

import { colors } from 'src/utils/colors';

import { getSpecialSaleState } from 'src/server/selectors/selector';
import staticBannerWeb from 'src/assets/images/specialSale/staticBannerWeb.webp';
import staticBannerMobile from 'src/assets/images/specialSale/staticBannerMobile.webp';
import { fetchSpecialSales, fetchAllOfferProduct } from 'src/server/slice/specialSaleSlice';

// ...
import Image from 'src/components/image';
import OspLink from 'src/components/link/osp-link';
import { useSettingsContext } from 'src/components/settings';

import TopSlider from 'src/sections/home/top-slider';
import ProductCardOne from 'src/sections/product/cards/product-card-one';

import { SpecialSaleItemSkeleton } from './special-sale-skeleton';
import SpecialSaleProductComp from './special-sale-item-products';

export default function SpecialSaleListView() {
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  const getText = useLocalizedText();

  const { isMobile, screenWidth } = useScreenWidth();
  const { specialSales, loading, products } = useSelector(getSpecialSaleState);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      limit: specialSales?.total || 20,
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchSpecialSales(credentials));
  }, [specialSales?.total, dispatch]);

  const total = products?.total;
  const limit = 20;
  const allPages = Math.ceil(total / limit);
  const [allProducts, setAllProducts] = useState([]);

  const [pages, setPages] = useState(1);

  const HandleFetchNextPage = () => {
    if (pages < allPages) {
      setPages((prevPages) => prevPages + 1);
    }
  };
  const paginatedGp = products?.offer_products;

  useEffect(() => {
    if (paginatedGp?.length) {
      setAllProducts((prevGroups) => {
        const combinedGroups = [...prevGroups, ...paginatedGp];
        const uniqueGroups = combinedGroups.filter(
          (group, index, self) => index === self.findIndex((g) => g.uid === group.uid)
        );
        return uniqueGroups;
      });
    }
  }, [pages, paginatedGp]);

  useEffect(() => {
    const credentials = {
      page: pages,
      search: '',
      limit,
      state: {
        filter_data: {
          is_deleted: false,
          is_active: true,
        },
        sort_data: {
          priority: 1,
        },
      },
    };
    dispatch(fetchAllOfferProduct(credentials));
  }, [products.total, dispatch, pages]);

  const SpecialSaleData = specialSales?.specialSales;

  const renderSkeleton = (
    <Grid container spacing={2} sx={{ p: 2 }}>
      {[...Array(16)].map((_, index) => (
        <Grid item key={index} xs={4} sm={3} md={3} lg={1.5}>
          <SpecialSaleItemSkeleton />
        </Grid>
      ))}
    </Grid>
  );

  const renderList = (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        mb: 15,
        my: isMobile ? 2 : 2.5,
      }}
    >
      <Stack>
        {SpecialSaleData?.length > 0 ? (
          SpecialSaleData?.map((item, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const translatedTitle = useTranslatedText(item?.title);

            return (
              <Stack mb={5}>
                {item?.banner_image?.url && <ImageComp item={item} isMobile={isMobile} />}

                <OspLink href="" sx={{ '&:hover': { textDecoration: 'none' } }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Box position="relative" mt={4}>
                      <Typography fontWeight={400} fontSize={isMobile ? 18 : 26} color="black">
                        {translatedTitle}
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: colors.themeSecondary,
                          height: 2,
                          width: 150,
                          position: 'absolute',
                          bottom: -9,
                        }}
                      />
                    </Box>
                  </Stack>
                  <Divider sx={{ mb: 2, mt: 1 }} />
                </OspLink>
                <SpecialSaleProductComp products={item} loading={loading} />
              </Stack>
            );
          })
        ) : (
          <InfiniteScroll
            scrollThreshold={0}
            dataLength={allProducts?.length || 0}
            next={HandleFetchNextPage}
            hasMore={pages < allPages}
            // loader={
            //   <Stack alignItems="center" spacing={1} justifyContent="center" sx={{ my: 10 }}>
            //     <Typography variant="body2">Hang on, loading inventory</Typography>
            //     <CircularProgress color="success" size={20} />
            //   </Stack>
            // }
          >
            <Grid container item spacing={1}>
              {allProducts?.map((product, key) => (
                <Grid item xs={6} sm={4} md={3} lg={2} key={key}>
                  <ProductCardOne product={product} />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        )}
      </Stack>
    </Container>
  );
  const getMarginTop = () => {
    if (isMobile) return 2;
    if (screenWidth < 768) return 20;

    if (screenWidth < 900) return 20;
    if (screenWidth < 1200) return 8;
    return 0;
  };
  return (
    <>
      {/* <Box mt={getMarginTop()} className={` ${mdDown ? 'mt-55' : ''}`}> */}
      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: getMarginTop() }}>
        {!isMobile && (
          <Box pt={1} pb={2}>
            <TopSlider />
          </Box>
        )}
        <Image
          src={isMobile ? staticBannerMobile : staticBannerWeb}
          alt={getText('special_sale')}
          imageStyle={{
            borderRadius: isMobile ? '4px' : '15px',
          }}
        />{' '}
      </Container>
      {/* </Box> */}

      {loading ? renderSkeleton : renderList}
    </>
  );
}

const ImageComp = ({ item, isMobile }) => {
  const transformedImage = useImageTransformation(item?.banner_image?.url, 'w_1600', true);
  const { days, hours, minutes, seconds } = useCountdown(item?.end_date);

  return (
    <Box position="relative" display="inline-block">
      <Image
        alt="banner"
        src={transformedImage}
        sx={{}}
        loading="eager"
        imageStyle={{
          height: isMobile ? 'unset' : '520px',
          objectFit: 'contain',
          borderRadius: isMobile ? '4px' : '15px',
          aspectRatio: isMobile ? 'unset' : '16/9',
        }}
      />
      {(days > 0 || hours > 0 || minutes > 0 || seconds > 0) && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#2874F0',
            color: 'white',
            padding: isMobile ? '5px' : '8px 16px',
            borderRadius: '8px 8px 0 0',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: isMobile ? '12px' : '16px',
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            component="span"
            fontSize={isMobile && '11px'}
            variant={isMobile ? 'caption' : 'body2'}
          >
            Deals Ends Within
          </Typography>
          <Typography
            component="span"
            fontSize={isMobile && '11px'}
            variant={isMobile ? 'caption' : 'body2'}
          >
            {days > 0 ? `${days}d ` : ''} {hours > 0 ? `${hours}h ` : ''}
            {minutes > 0 ? `${minutes}m ` : ''}
            {seconds > 0 ? `${seconds}s ` : ''}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

ImageComp.propTypes = {
  item: PropTypes.any,
  isMobile: PropTypes.bool,
};
// const SpecialSaleProductComp = ({ item }) =>
//   item?.items?.length ? (
//     <Grid container  mt={2}spacing={1}>
//       {item.items.map((product, key) => (
//         <Grid item xs={6} lg={2} key={key}>
//           <ProductCardOne product={product} />
//         </Grid>
//       ))}
//     </Grid>
//   ) : null;

// SpecialSaleProductComp.propTypes = {
//   item: PropTypes.any,
// };
