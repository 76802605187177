import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { usePaths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetToken } from 'src/hooks/useHandleSessions';
import useTranslatedText from 'src/hooks/useTranslatedText';

import { timeZone, TOKEN_PREFIX } from 'src/server/http';
import { getCartState } from 'src/server/selectors/selector';
import { updateMyCart, updateLocalCart } from 'src/server/slice/cartOrderSlice';

import TextMaxLine from 'src/components/text-max-line';

const useHandleCart = ({ product }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { isSuccess, token } = useGetToken(TOKEN_PREFIX);
  const paths = usePaths();

  const { cart } = useSelector(getCartState);

  const translatedProductName = useTranslatedText(product?.name ? product?.name : '');
  const outOfStock = product?.stock <= 0;

  const cartUpdateSuccess = useBoolean();

  const useProductOfferPriceCalculator = () => {
    const oldPrice = product?.price;
    const currentPrice = product?.offer_price;

    const diff = Math.abs(oldPrice - currentPrice);

    const currentDateNormalDate = new Date();
    const currentDate = new Date(currentDateNormalDate.toLocaleString('en-US', { timeZone }));

    const offerStartDate = new Date(product?.offer_start_date);
    const offerEndDate = new Date(product?.offer_end_date);

    const offerPrice =
      currentDate >= offerStartDate && currentDate <= offerEndDate ? currentPrice : null;

    const discountPercentage =
      (oldPrice ?? 0) > currentPrice ? Math.floor((diff / oldPrice) * 100) : '';

    return {
      discountPercentage: offerPrice !== null && discountPercentage,
      offerPrice,
    };
  };

  const { offerPrice } = useProductOfferPriceCalculator(product);

  const handleAddItemToLocalCart = (quantity, action) => {
    const updatedCart = {
      ...cart,
      cart_items: cart?.cart_items?.length > 0 ? [...cart.cart_items] : [],
      items_price: cart?.items_price ?? 0,
      total_discount: cart?.total_discount ?? 0,
      total_price: cart?.total_price ?? 0,
    };

    const productPrice = offerPrice || product?.price || 0;
    updatedCart.items_price = 0;
    updatedCart.total_discount = 0;
    updatedCart.total_price = 0;

    const existingCartItemIndex = updatedCart.cart_items.findIndex(
      (item) => item.product?.uid === product?.uid
    );

    if (existingCartItemIndex >= 0) {
      const existingCartItem = updatedCart.cart_items[existingCartItemIndex];

      const updatedCartItem = {
        ...existingCartItem,
        quantity: existingCartItem.quantity + quantity,
        product_price: offerPrice || productPrice || 0,
      };

      updatedCart.cart_items = [
        ...updatedCart.cart_items.slice(0, existingCartItemIndex),
        updatedCartItem,
        ...updatedCart.cart_items.slice(existingCartItemIndex + 1),
      ];
    } else {
      updatedCart?.cart_items?.push({
        product,
        quantity,
        product_price: offerPrice || product?.price || 0,
        is_out_of_stock: false,
      });
    }

    updatedCart.items_price = 0;
    updatedCart.total_discount = 0;

    updatedCart.cart_items.forEach((item) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { offerPrice: itemOfferPrice } = useProductOfferPriceCalculator(item.product);

      const itemPrice = itemOfferPrice || item.product?.price || 0;
      const originalPrice = item.product?.price || 0;

      updatedCart.items_price += itemPrice * item.quantity;
      if (itemOfferPrice) {
        updatedCart.total_discount += (originalPrice - itemPrice) * item.quantity;
      }
    });

    updatedCart.total_price = updatedCart.items_price;

    const credentials = {
      state: {
        updatedCart,
      },
      customNotification: enqueueSnackbar,
      cartUpdateSuccess: cartUpdateSuccess.onTrue(),
      action,
      router,
      paths,
      enqueueSnackbar: () =>
        enqueueSnackbar(
          <Typography variant="caption" component="div">
            <TextMaxLine variant="caption" line={1} sx={{ maxWidth: '400px' }}>
              &quot;{translatedProductName}&quot;
            </TextMaxLine>
            added to your cart
          </Typography>,
          { variant: 'success' }
        ),
    };

    dispatch(updateLocalCart(credentials));
  };

  const handleAddCart = (qty, action) => {
    const quantity = qty || 1;

    if (outOfStock) {
      enqueueSnackbar(
        <Typography variant="caption" component="div">
          <TextMaxLine variant="caption" line={1} sx={{ maxWidth: '400px' }}>
            &quot;{translatedProductName}&quot;
          </TextMaxLine>
          is out of stock
        </Typography>,
        { variant: 'info' }
      );
      return;
    }

    if (token !== null && isSuccess && token && !outOfStock) {
      const credentials = {
        state: {
          operation: 'ADD',
          quantity,
          product: product?.uid,
        },
        cartUpdateSuccess: cartUpdateSuccess.onTrue(),
        action,
        router,
        paths,
        customNotification: enqueueSnackbar,
        enqueueSnackbar: () =>
          enqueueSnackbar(
            <Typography variant="caption" component="div">
              <TextMaxLine variant="caption" line={1} sx={{ maxWidth: '400px' }}>
                &quot;{translatedProductName}&quot;
              </TextMaxLine>
              added to your cart
            </Typography>,
            { variant: 'success' }
          ),
      };
      dispatch(updateMyCart(credentials));
    } else {
      handleAddItemToLocalCart(quantity, action);
    }
  };

  const handleRemoveItemFromLocalCart = (quantity = 1) => {
    const updatedCart = { ...cart };

    const existingCartItemIndex = updatedCart.cart_items.findIndex(
      (item) => item.product?.uid === product?.uid
    );

    if (existingCartItemIndex >= 0) {
      const existingCartItem = updatedCart.cart_items[existingCartItemIndex];

      if (existingCartItem.quantity - quantity <= 0) {
        updatedCart.cart_items = [
          ...updatedCart.cart_items.slice(0, existingCartItemIndex),
          ...updatedCart.cart_items.slice(existingCartItemIndex + 1),
        ];
      } else {
        const updatedCartItem = {
          ...existingCartItem,
          quantity: existingCartItem.quantity - quantity,
        };

        updatedCart.cart_items = [
          ...updatedCart.cart_items.slice(0, existingCartItemIndex),
          updatedCartItem,
          ...updatedCart.cart_items.slice(existingCartItemIndex + 1),
        ];
      }

      updatedCart.items_price = 0;
      updatedCart.total_discount = 0;

      updatedCart.cart_items.forEach((item) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { offerPrice: itemOfferPrice } = useProductOfferPriceCalculator(item.product);

        const itemPrice = itemOfferPrice || item.product?.price || 0;
        const originalPrice = item.product?.price || 0;

        updatedCart.items_price += itemPrice * item.quantity;
        if (item.product?.offer_price) {
          updatedCart.total_discount += (originalPrice - itemPrice) * item.quantity;
        }
      });

      updatedCart.total_price = (updatedCart.items_price || 0) - (updatedCart.total_discount || 0);

      const credentials = {
        state: {
          updatedCart,
        },
        paths,
        customNotification: enqueueSnackbar,
        cartUpdateSuccess: cartUpdateSuccess.onTrue(),
        enqueueSnackbar: () =>
          enqueueSnackbar(
            <Typography variant="caption" component="div">
              <TextMaxLine variant="caption" line={1} sx={{ maxWidth: '400px' }}>
                &quot;{translatedProductName}&quot;
              </TextMaxLine>
              removed from your cart
            </Typography>,
            { variant: 'success' }
          ),
      };

      dispatch(updateLocalCart(credentials));
    } else {
      enqueueSnackbar(
        <Typography variant="caption" component="div">
          <TextMaxLine variant="caption" line={1} sx={{ maxWidth: '400px' }}>
            &quot;{translatedProductName}&quot; is not in your cart
          </TextMaxLine>
        </Typography>,
        { variant: 'info' }
      );
    }
  };

  const handleRemoveCart = (quantity) => {
    if (isSuccess && token) {
      const credentials = {
        state: {
          operation: 'REMOVE',
          quantity: quantity || 1,
          product: product?.uid,
        },
        paths,
        customNotification: enqueueSnackbar,
        enqueueSnackbar: () =>
          enqueueSnackbar(
            <Typography variant="caption" component="div">
              Successfully removed
              <TextMaxLine variant="caption" line={1} sx={{ maxWidth: '400px' }}>
                &quot;{translatedProductName}&quot; from your cart
              </TextMaxLine>
            </Typography>,
            {
              variant: 'success',
            }
          ),
      };
      dispatch(updateMyCart(credentials));
    } else {
      handleRemoveItemFromLocalCart(quantity);
    }
  };

  return { handleAddCart, handleRemoveCart, isSuccess: cartUpdateSuccess.value };
};

export default useHandleCart;

// updatedCart.items_price = 0;
// updatedCart.cart_items.forEach((item) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const itemPrice = useProductOfferPriceCalculator(item).offerPrice || item.product?.price || 0;
//   updatedCart.items_price += itemPrice * item.quantity;
// });
// updatedCart.total_price = (updatedCart?.items_price ?? 0) - (updatedCart.total_discount ?? 0);

// updatedCart.items_price = 0;
// updatedCart.cart_items.forEach((item) => {
//   const itemPrice = item.product?.offer_price || item.product?.price || 0;
//   updatedCart.items_price += itemPrice * item.quantity;
// });
