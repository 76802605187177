import { useState, useEffect } from 'react';

const useCountdown = (targetDate) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    currentTimeMuscat: '',
  });

  useEffect(() => {
    const updateCountdown = () => {
      const nowUtc = new Date();
      const nowMuscat = new Date(nowUtc.toLocaleString('en-US', { timeZone: 'Asia/Muscat' }));

      // Ensure the target date includes the full day (set time to 23:59:59)
      const targetDateObj = new Date(targetDate);
      targetDateObj.setHours(23, 59, 59, 999); // Set to last second of the day

      // Add 1 hour 30 minutes to the target time
      targetDateObj.setMinutes(targetDateObj.getMinutes() + 90);

      const targetUtc = targetDateObj;
      const targetMuscat = new Date(targetUtc.toLocaleString('en-US', { timeZone: 'Asia/Muscat' }));

      const diffMs = targetMuscat - nowMuscat;

      if (diffMs <= 0) {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          currentTimeMuscat: nowMuscat.toLocaleString('en-US', { timeZone: 'Asia/Muscat' }),
        });
        return;
      }

      const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

      setTimeLeft({
        days,
        hours,
        minutes,
        seconds,
        currentTimeMuscat: nowMuscat.toLocaleString('en-US', { timeZone: 'Asia/Muscat' }),
      });
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return timeLeft;
};

export default useCountdown;
