// store/bannerSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { useGetToken } from 'src/hooks/useHandleSessions';

import { get, post, TOKEN_PREFIX, defaultPaymentMethod } from '../http';

export const CART_KEY = 'userCart';

// Thunk to fetch my cart
export const fetchMyCart = createAsyncThunk('cart/my-cart', async (_, { rejectWithValue }) => {
  const { isSuccess, token } = useGetToken(TOKEN_PREFIX);
  try {
    if (isSuccess && token) {
      const response = await get(`/cart/user/me`);
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    }
    return '';
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Network Error');
  }
});

// Thunk to fetch update cart
export const updateMyCart = createAsyncThunk(
  'cart/update',
  async (
    { state, enqueueSnackbar, customNotification, cartUpdateSuccess, action, router, paths },
    { rejectWithValue }
  ) => {
    try {
      const response = await post(`/cart/user/update`, state);
      if (response.status === 2001) {
        customNotification(response?.message, {
          variant: 'info',
        });
      }
      if (response.status === 2000) {
        if (enqueueSnackbar) {
          enqueueSnackbar();
        }
        if (cartUpdateSuccess) {
          cartUpdateSuccess();
        }
        if (action === 'buy') {
          router?.push(paths.public.cart);
        }
        return response.data;
      }

      customNotification(response?.message, {
        variant: 'info',
      });

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// update local cart&nbsp;
export const updateLocalCart = createAsyncThunk(
  'cart/local/update',
  async (
    { state, enqueueSnackbar, cartUpdateSuccess, action, router, paths },
    { rejectWithValue }
  ) => {
    try {
      const response = state?.updatedCart;
      localStorage.setItem(CART_KEY, JSON.stringify(response));
      localStorage.setItem('isLocalCart', true);
      if (response?.cart_items?.length <= 0) {
        localStorage.setItem('isLocalCart', false);
      }
      if (action === 'buy') {
        router?.push(paths.public.cart);
      }

      if (enqueueSnackbar) {
        enqueueSnackbar();
      }
      if (cartUpdateSuccess) {
        cartUpdateSuccess();
      }

      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const getLocalCart = createAsyncThunk('cart/local/get', async (_, { rejectWithValue }) => {
  try {
    const response = localStorage.getItem(CART_KEY);
    return response
      ? JSON.parse(response)
      : {
          cart_items: [],
          items_price: 0,
          shipping_price: 0,
          total_discount: 0,
          total_price: 0,
        };
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Network Error');
  }
});

// upload bulk
export const uploadBulkCart = createAsyncThunk(
  'cart/bulk/update',
  async ({ state, enqueueSnackbar, route, path }, { rejectWithValue }) => {
    try {
      const response = await post(`/cart/user/bulk/update`, state);
      if (response.status === 2000) {
        localStorage.removeItem(CART_KEY);
        localStorage.setItem('isLocalCart', false);

        enqueueSnackbar('Cart updated', {
          variant: 'success',
        });
        route.push(path);
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const validateCoupon = createAsyncThunk(
  'coupon/validate',
  async ({ state, customNotification, dispatch, setDiscountCode }, { rejectWithValue }) => {
    const { token } = useGetToken(TOKEN_PREFIX);
    try {
      const response = await post(`coupon/user/validate`, state);
      if (response.status === 2000) {
        customNotification('Coupon applied successfully', {
          variant: 'success',
        });
        if (token) {
          dispatch(fetchMyCart());
        } else {
          dispatch(getLocalCart());
        }
        if (setDiscountCode) {
          setDiscountCode('');
        }
        return response.data;
      }
      if (response.status === 2001) {
        customNotification(response.message, {
          variant: 'error',
        });
        return response.message;
      }
      // customNotification(response?.message, {
      //   variant: 'success',
      // });

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const resetCoupon = createAsyncThunk(
  'coupon/reset',
  async ({ customNotification, dispatch }, { rejectWithValue }) => {
    const { token } = useGetToken(TOKEN_PREFIX);
    try {
      const response = await get(`cart/user/coupon/reset`);
      if (response.status === 2000) {
        customNotification('Coupon removed successfully', {
          variant: 'success',
        });
        if (token) {
          dispatch(fetchMyCart());
        } else {
          dispatch(getLocalCart());
        }
        return response.data;
      }
      if (response.status === 2001) {
        customNotification(response.message, {
          variant: 'error',
        });
        return response.message;
      }
      // customNotification(response?.message, {
      //   variant: 'success',
      // });

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
export const createCODOrder = createAsyncThunk(
  'create-order-cod/validate',
  async ({ state, customNotification, route, customLoading, paths }, { rejectWithValue }) => {
    try {
      const response = await post(`order/user/new`, state);

      if (response.status === 2000) {
        customNotification('Order created successfully', {
          variant: 'success',
        });
        route(paths.private.order_success(response?.data?.uid));
        return response.data;
      }

      if (response.status === 2001) {
        customNotification(response.message, {
          variant: 'info',
        });
        customLoading.onFalse();
        return response.message;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const createOnlineOrder = createAsyncThunk(
  'create-order-online/validate',
  async ({ state, customNotification, route, customLoading, paths }, { rejectWithValue }) => {
    try {
      const response = await post(`order/user/new`, state);

      if (response.status === 2000) {
        customNotification('Online Order Initiated successfully', {
          variant: 'success',
        });
        if (response.data) {
          route(paths.private.order_redirect, { state: response.data });
        }
        return response.data;
      }

      if (response.status === 2001) {
        customNotification(response.message, {
          variant: 'info',
        });
        customLoading.onFalse();
        return response.message;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const updateOrder = createAsyncThunk(
  'update-order-cod/validate',
  async ({ state }, { rejectWithValue }) => {
    try {
      const response = await post(`/order/user/update/data`, state);

      if (response.status === 2000) {
        return response.data;
      }
      if (response.status === 2001) {
        return rejectWithValue(response.message || 'Something went wrong');
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const fetchAllOrder = createAsyncThunk(
  'order/all',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/order/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const fetchOrderSingleView = createAsyncThunk(
  'order/fetchOrderSingleView',
  async ({ orderId }, { rejectWithValue }) => {
    try {
      const response = await get(`/order/user/single/${orderId}`);
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const orderStatusUpdate = createAsyncThunk(
  'update-order-status',
  async ({ state, customNotification }, { rejectWithValue }) => {
    try {
      const response = await post(`/order/user/status/update`, state);
      if (response.status === 2000) {
        customNotification('Order status updated', {
          variant: 'success',
        });
        return response.data;
      }
      if (response.status === 2001) {
        return rejectWithValue(response.message || 'Something went wrong');
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

const cartOrderSlice = createSlice({
  name: 'orders',
  initialState: {
    loading: false,
    bulkLoading: false,
    cartUpdateLoading: false,
    couponLoading: false,

    error: null,
    cart: [],
    coupon: [],
    resetCoupon: {},
    orders: [],
    orderSingleView: {},
    onlineOrderData: {},
    localState: {
      payment_type: defaultPaymentMethod,
      delivery_type: 'HOME_DELIVERY',
      order_type: 'DIRECT',
    },
    orderStatusLoading: false,
    cache: {},
  },
  reducers: {
    clearCartState: (state) => {
      state.cart = [];
    },
    handleOrderLocalData: (state, action) => {
      state.localState = {
        ...state.localState,
        ...action.payload.state,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocalCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocalCart.fulfilled, (state, action) => {
        state.loading = false;
        state.cart = action.payload;
        state.error = null;
      })
      .addCase(getLocalCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchMyCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMyCart.fulfilled, (state, action) => {
        state.loading = false;
        state.cart = action.payload;
        state.error = null;
      })
      .addCase(fetchMyCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateMyCart.pending, (state) => {
        state.cartUpdateLoading = true;
      })
      .addCase(updateMyCart.fulfilled, (state, action) => {
        state.cartUpdateLoading = false;
        state.cart = action.payload;
        state.error = null;
      })
      .addCase(updateMyCart.rejected, (state, action) => {
        state.cartUpdateLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateLocalCart.pending, (state) => {
        state.cartUpdateLoading = true;
      })
      .addCase(updateLocalCart.fulfilled, (state, action) => {
        state.cartUpdateLoading = false;
        state.cart = action.payload;
        state.error = null;
      })
      .addCase(updateLocalCart.rejected, (state, action) => {
        state.cartUpdateLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(uploadBulkCart.pending, (state) => {
        state.bulkLoading = true;
      })
      .addCase(uploadBulkCart.fulfilled, (state, action) => {
        state.bulkLoading = false;
        state.cart = action.payload;
        state.error = null;
      })
      .addCase(uploadBulkCart.rejected, (state, action) => {
        state.bulkLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(validateCoupon.pending, (state) => {
        state.couponLoading = true;
      })
      .addCase(validateCoupon.fulfilled, (state, action) => {
        state.couponLoading = false;
        state.coupon = action.payload;
        state.error = null;
      })
      .addCase(validateCoupon.rejected, (state, action) => {
        state.couponLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(resetCoupon.pending, (state) => {
        state.couponLoading = true;
      })
      .addCase(resetCoupon.fulfilled, (state, action) => {
        state.couponLoading = false;
        state.resetCoupon = action.payload;
        state.error = null;
      })
      .addCase(resetCoupon.rejected, (state, action) => {
        state.couponLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchAllOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;

        state.error = null;
      })
      .addCase(fetchAllOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchOrderSingleView.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrderSingleView.fulfilled, (state, action) => {
        state.loading = false;
        state.orderSingleView = action.payload;

        state.error = null;
      })
      .addCase(fetchOrderSingleView.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(orderStatusUpdate.pending, (state) => {
        state.orderStatusLoading = true;
      })
      .addCase(orderStatusUpdate.fulfilled, (state, action) => {
        state.orderStatusLoading = false;
        state.orderSingleView = action.payload;
        state.error = null;
      })
      .addCase(orderStatusUpdate.rejected, (state, action) => {
        state.orderStatusLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.cart = action.payload;
        state.error = null;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createOnlineOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOnlineOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.onlineOrderData = action.payload;
        state.error = null;
      })
      .addCase(createOnlineOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearCartState, handleOrderLocalData } = cartOrderSlice.actions;

export default cartOrderSlice.reducer;
