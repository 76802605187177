// store/productSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { get, post } from '../http';
// import { covertToJSON } from "../../util/stateToJson";

export const fetchSearchProducts = createAsyncThunk(
  'product/fetch-search-product',
  async ({ page, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(`/product/user/search/all?page=${page}&limit=${limit}`, state);
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const fetchAllProducts = createAsyncThunk(
  'product/fetchProducts',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/product/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to fetch products
export const fetchAllBrandProducts = createAsyncThunk(
  'product/brand/fetchProducts',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/product/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

export const fetchAllCategoryProducts = createAsyncThunk(
  'product/fetchCategoryProducts',
  async ({ page, search, limit, state }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/product/user/all?page=${page}&search=${search}&limit=${limit}`,
        state
      );
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// get product details
export const fetchProductDetails = createAsyncThunk(
  'product/fetchProductDetails',
  async ({ productName }, { rejectWithValue }) => {
    try {
      const response = await get(`/product/user/single/${productName}`);
      if (response.status === 2000) {
        return response.data;
      }
      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// export const fetchMainProductDetails = createAsyncThunk(
//   'product/fetchMainProductDetails',
//   async ({ productName }, { rejectWithValue }) => {
//     try {
//       const response = await get(`/product/user/single/${productName}`);
//       if (response.status === 2000) {
//         return response.data;
//       }
//       return rejectWithValue(response.message || 'Something went wrong');
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Network Error');
//     }
//   }
// );

// get product group
export const fetchProductGroups = createAsyncThunk(
  'product/fetchProductGroups',
  async ({ productId }, { rejectWithValue }) => {
    try {
      const response = await get(`/product-group/user/product/${productId}`);

      if (response.status === 2000) {
        return response.data;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// get related product
export const fetchRelatedProduct = createAsyncThunk(
  'product/fetchRelatedProduct',
  async ({ productId, state }, { rejectWithValue }) => {
    try {
      const response = await post(`/product/user/related/${productId}`, state);

      if (response.status === 2000) {
        return response.data;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// get product group
export const checkProductPurchase = createAsyncThunk(
  'check/productPurchase',
  async ({ productId }, { rejectWithValue }) => {
    try {
      const response = await get(`/order/user/product/check/${productId}`);

      if (response.status === 2000) {
        return response.data;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// get product group
// export const fetchAllVariantsOfMainProduct = createAsyncThunk(
//   'getAllVariant/mainProduct',
//   async ({ productId }, { rejectWithValue }) => {
//     try {
//       const response = await post(`/product/user/variant/all/${productId}`);

//       if (response.status === 2000) {
//         return response.data;
//       }

//       return rejectWithValue(response.message || 'Something went wrong');
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Network Error');
//     }
//   }
// );

// get product summery
export const fetchVariantSummery = createAsyncThunk(
  'getVariantSummery/mainProduct',
  async ({ mainProductId, newVariationData }, { rejectWithValue }) => {
    try {
      const response = await post(
        `/product/user/variant/summary/${mainProductId}`,
        newVariationData
      );

      if (response.status === 2000) {
        return response.data;
      }

      return rejectWithValue(response.message || 'Something went wrong');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

const defaultFilterState = {
  search: '',
  isInputChanged: false,
  limit: 30,
  filter_data: {
    is_deleted: false,
    is_active: true,
    is_variant: false,
    average_rating: {
      $gte: 0,
    },

    // average_rating: {
    //   $in: [],
    // },
  },
  sort_data: {
    createdAt: -1,
  },
  categoryLabel: [],
  brandLabel: [],
  cache: {},
  // other filters and sorts
};

const productSlice = createSlice({
  name: 'product',
  initialState: {
    loading: true,
    detailLoading: false,
    productGroupLoading: false,
    checkProductPurchaseLoading: false,
    relatedProductLoading: false,
    error: null,
    products: [],
    categoryProducts: [],
    categoryProdLoading: false,
    productFilterObject: defaultFilterState,
    productDetails: {},
    mainProductDetails: {},
    productGroups: [],
    relatedProduct: [],
    brandProducts: [],
    checkProductPurchaseStatus: {},
    brandProductLoading: true,
    cache: {},
    searchedProduct: [],
    variantSummery: [],
    variantLoading: false,
  },
  reducers: {
    clearProductState: (state) => {
      state.products = [];
    },
    productFilterObjectState: (state, action) => {
      state.productFilterObject = {
        ...state.productFilterObject,
        ...action.payload,
        filter_data: {
          ...state.productFilterObject.filter_data,
          ...action.payload.filter_data,
        },
      };
    },
    removeProductFilterObjectState: (state, action) => {
      switch (action?.payload?.type) {
        case 'clear_all':
          state.productFilterObject = defaultFilterState;
          break;
        case 'sort':
          state.productFilterObject = {
            ...state.productFilterObject,
            sort_data: {
              createdAt: -1,
            },
          };
          break;
        case 'search':
          state.productFilterObject = {
            ...state.productFilterObject,
            search: '',
          };
          break;
        case 'price':
          state.productFilterObject = {
            ...state.productFilterObject,
            filter_data: {
              ...state.productFilterObject.filter_data,
              price: '',
            },
          };
          break;
        case 'free_delivery':
          state.productFilterObject = {
            ...state.productFilterObject,
            filter_data: {
              ...state.productFilterObject.filter_data,
              is_free_delivery_product: false,
            },
          };
          break;
        case 'ratings':
          state.productFilterObject = {
            ...state.productFilterObject,
            filter_data: {
              ...state.productFilterObject.filter_data,
              average_rating: {
                $gte: action.payload?.item,
              },
            },
          };
          break;
        case 'badge':
          state.productFilterObject = {
            ...state.productFilterObject,
            filter_data: {
              ...state.productFilterObject.filter_data,
              brands: {
                ...state.productFilterObject.filter_data.brands,
                $in: state.productFilterObject.filter_data.brands?.$in?.filter(
                  (i) => i !== action.payload?.item
                ),
              },
            },
          };
          state.productFilterObject = {
            ...state.productFilterObject,
            brandLabel: state.productFilterObject?.brandLabel?.filter(
              (i) => i?.uid !== action.payload?.item
            ),
          };
          break;

        case 'category':
          state.productFilterObject = {
            ...state.productFilterObject,
            filter_data: {
              ...state.productFilterObject.filter_data,
              categories: {
                ...state.productFilterObject.filter_data.categories,
                $in: state.productFilterObject.filter_data.categories?.$in?.filter(
                  (i) => i !== action.payload?.item
                ),
              },
            },
          };
          state.productFilterObject = {
            ...state.productFilterObject,
            categoryLabel: state.productFilterObject?.categoryLabel?.filter(
              (i) => i?.uid !== action.payload?.item
            ),
          };

          break;
        default:
          break;
      }
    },
    updateProductDetailsWithVariantId: (state, action) => {
      state.productDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBrandProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllBrandProducts.fulfilled, (state, action) => {
        state.brandProductLoading = false;
        state.brandProducts = action.payload;
        state.error = null;
      })
      .addCase(fetchAllBrandProducts.rejected, (state, action) => {
        state.brandProductLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchAllProducts.pending, (state) => {
        state.loading = true;
      })
      // cache
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
        state.error = null;
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchAllCategoryProducts.pending, (state) => {
        state.categoryProdLoading = true;
      })
      // cache
      .addCase(fetchAllCategoryProducts.fulfilled, (state, action) => {
        state.categoryProdLoading = false;
        state.categoryProducts = action.payload;
        state.error = null;
      })
      .addCase(fetchAllCategoryProducts.rejected, (state, action) => {
        state.categoryProdLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchSearchProducts.pending, (state) => {
        state.loading = true;
      })
      // cache
      .addCase(fetchSearchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.searchedProduct = action.payload;
        state.error = null;
      })
      .addCase(fetchSearchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchProductDetails.pending, (state) => {
        // state.detailLoading = true;
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        // state.detailLoading = false;
        state.productDetails = action.payload;
        state.error = null;
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        // state.detailLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchProductGroups.pending, (state) => {
        state.productGroupLoading = true;
      })
      .addCase(fetchProductGroups.fulfilled, (state, action) => {
        state.productGroupLoading = false;
        state.productGroups = action.payload;
        state.error = null;
      })
      .addCase(fetchProductGroups.rejected, (state, action) => {
        state.productGroupLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchRelatedProduct.pending, (state) => {
        state.relatedProductLoading = true;
      })
      .addCase(fetchRelatedProduct.fulfilled, (state, action) => {
        state.relatedProductLoading = false;
        state.relatedProduct = action.payload;
        state.error = null;
      })
      .addCase(fetchRelatedProduct.rejected, (state, action) => {
        state.relatedProductLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(checkProductPurchase.pending, (state) => {
        state.checkProductPurchaseLoading = true;
      })
      .addCase(checkProductPurchase.fulfilled, (state, action) => {
        state.checkProductPurchaseLoading = false;
        state.checkProductPurchaseStatus = action.payload;
        state.error = null;
      })
      .addCase(checkProductPurchase.rejected, (state, action) => {
        state.checkProductPurchaseLoading = false;
        state.error = action.error.message;
      });

    // builder
    //   .addCase(fetchMainProductDetails.pending, (state) => {
    //     // state.detailLoading = true;
    //   })
    //   .addCase(fetchMainProductDetails.fulfilled, (state, action) => {
    //     // state.detailLoading = false;
    //     state.mainProductDetails = action.payload;
    //     state.error = null;
    //   })
    //   .addCase(fetchMainProductDetails.rejected, (state, action) => {
    //     // state.detailLoading = false;
    //     state.error = action.error.message;
    //   });
    builder
      .addCase(fetchVariantSummery.pending, (state) => {
        state.variantLoading = true;
      })
      .addCase(fetchVariantSummery.fulfilled, (state, action) => {
        state.variantLoading = false;
        state.variantSummery = action.payload;
        state.error = null;
      })
      .addCase(fetchVariantSummery.rejected, (state, action) => {
        state.variantLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  updateProductDetailsWithVariantId,
  clearProductState,
  productFilterObjectState,
  removeProductFilterObjectState,
} = productSlice.actions;

export default productSlice.reducer;
