import React, { Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import FAQ from 'src/pages/legal/FAQ';
import { AuthGuard } from 'src/auth/guard';
import WebPageList from 'src/pages/WebPageList';
import SpecialSalePage from 'src/pages/SpecialSale';

import { SplashScreen } from 'src/components/loading-screen';

const MainLayout = React.lazy(() => import('src/layouts/main'));
const HomePage = React.lazy(() => import('src/pages/HomePage'));
const CartPage = React.lazy(() => import('src/pages/CartPage'));
const BrandPage = React.lazy(() => import('src/pages/BrandPage'));
const LoginPage = React.lazy(() => import('src/pages/LoginPage'));
const SearchPage = React.lazy(() => import('src/pages/SearchPage'));
const Page403 = React.lazy(() => import('src/pages/error/Page403'));
const Page500 = React.lazy(() => import('src/pages/error/Page500'));
const Page404 = React.lazy(() => import('src/pages/error/Page404'));
const ProductPage = React.lazy(() => import('src/pages/ProductPage'));
const CheckoutPage = React.lazy(() => import('src/pages/CheckoutPage'));
const OrderFailPage = React.lazy(() => import('src/pages/OrderFailPage'));
const CollectionPage = React.lazy(() => import('src/pages/CollectionPage'));
const OfflinePage = React.lazy(() => import('src/pages/error/OfflinePage'));
const BrandSinglePage = React.lazy(() => import('src/pages/BrandSinglePage'));
const OrderSuccessPage = React.lazy(() => import('src/pages/OrderSuccessPage'));
const OnlineOrderRedirectPage = React.lazy(() => import('src/pages/OnlineOrderRedirectPage'));
const ProductSinglePage = React.lazy(() => import('src/pages/ProductSinglePage'));
const CategorySinglePage = React.lazy(() => import('src/pages/CategorySinglePage'));
const MyAccountPage = React.lazy(() => import('src/pages/my-account/MyAccountPage'));
const AccountLayout = React.lazy(() => import('src/pages/my-account/AccountLayout'));
const CollectionSinglePage = React.lazy(() => import('src/pages/CollectionSinglePage'));
const PrivacyPolicyPage = React.lazy(() => import('src/pages/legal/PrivacyPolicyPage'));
const TermsAndConditions = React.lazy(() => import('src/pages/legal/TeamsAndCondition'));
const OrderSinglePageScreen = React.lazy(() => import('src/pages/my-account/OrderSinglePage'));
const Section = React.lazy(() => import('src/sections/webPages/view'));

export const generateRoutes = (lang) => {
  const basePath = lang === 'ar' ? '/ar' : '/';

  const publicRoutes = [
    {
      path: basePath,
      element: (
        <MainLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [
        { path: '', element: <HomePage /> },
        { path: 'collections', element: <CollectionPage /> },
        { path: 'collection/:id', element: <CollectionSinglePage /> },
        { path: 'category/:id', element: <CategorySinglePage /> },

        { path: 'brands', element: <BrandPage /> },
        { path: 'brand/:id', element: <BrandSinglePage /> },
        { path: 'deals', element: <SpecialSalePage /> },

        { path: 'products', element: <ProductPage /> },
        { path: 'product/:id', element: <ProductSinglePage /> },

        { path: 'cart', element: <CartPage /> },

        { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
        { path: 'terms-and-conditions', element: <TermsAndConditions /> },
        { path: 'shipping-policy', element: <PrivacyPolicyPage /> },
        { path: 'faq', element: <FAQ /> },

        { path: 'pages', element: <WebPageList /> },
        { path: 'page/:id', element: <Section /> },

        { path: 'login', element: <LoginPage /> },
        // { path: 'search', element: <SearchPage /> },
      ],
    },
    {
      path: basePath,
      element: (
        <MainLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [
        { path: 'error/500', element: <Page500 /> },
        { path: 'error/403', element: <Page403 /> },
        { path: 'error/404', element: <Page404 /> },
      ],
    },
    {
      path: basePath,
      children: [{ path: 'search', element: <SearchPage /> }],
    },
    // { path: 'search', element: <SearchPage /> },
    { path: 'error/offline', element: <OfflinePage /> },

    {
      path: '/404',
      element: (
        <MainLayout>
          <Page404 />
        </MainLayout>
      ),
    },
    { path: '*', element: <Navigate to="/404" /> },

    // auth
    {
      path: basePath,
      element: (
        <AuthGuard>
          <MainLayout>
            <Suspense fallback={<SplashScreen />}>
              <Outlet />
            </Suspense>
          </MainLayout>
        </AuthGuard>
      ),

      children: [
        {
          path: 'my-account',
          element: (
            <AccountLayout>
              <Suspense fallback={<SplashScreen />}>
                <Outlet />
              </Suspense>
            </AccountLayout>
          ),
          children: [
            { path: '', element: <MyAccountPage /> },
            { path: 'orders/:id', element: <OrderSinglePageScreen /> },
          ],
        },

        { path: 'checkout', element: <CheckoutPage /> },
        { path: 'order-fail', element: <OrderFailPage /> },
        { path: 'order-success/:id', element: <OrderSuccessPage /> },
        { path: 'order-redirect', element: <OnlineOrderRedirectPage /> },
      ],
    },
  ];

  return { publicRoutes };
};
