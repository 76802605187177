import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Stack } from '@mui/system';
import { Grid, Button } from '@mui/material';

import useLocalizedText from 'src/hooks/useLocalizedText';

import ProductCardOne from 'src/sections/product/cards/product-card-one';
import { MyProfileReviewSkeleton } from 'src/sections/myAccount/review/review-skeleton';

// ----------------------------------------------------------------------

export default function SpecialSaleProductComp({ products, loading }) {
  // pagination taken from review my account section so refer that
  const [visibleCount, setVisibleCount] = useState(12);

  const totalItems = products?.items?.length || 0;
  const handleViewMore = () => {
    setVisibleCount(totalItems);
  };
  const getText = useLocalizedText();

  return (
    <>
      {loading ? (
        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
          <MyProfileReviewSkeleton />
        </Grid>
      ) : (
        <Stack spacing={1} mb={4}>
          <Grid container mt={2} spacing={1}>
            {(products?.items || []).slice(0, visibleCount).map((product) =>
              product ? (
                <Grid item xs={6} lg={2} key={product.id || product.name}>
                  <ProductCardOne product={product} />
                </Grid>
              ) : null
            )}
          </Grid>
          {totalItems > 12 && visibleCount < totalItems && (
            <Grid container justifyContent="center" mt={2}>
              <Button
                variant="contained"
                onClick={handleViewMore}
                sx={{ backgroundColor: '#F3F3FC', fontWeight:'400',color: 'black', paddingX: '65px' ,borderRadius:'4px',boxShadow:'unset',
                  '&:hover': {
                    backgroundColor: '#F3F3FC'
                  }
                }}
              >
                {getText('view_more')}
              </Button>
            </Grid>
          )}
          {/* {totalReview > 6 && (
            <Pagination
              onChange={(e, newPage) => setCurrentPage(newPage)}
              count={totalPages || 8}
              page={currentPage}
              sx={{
                my: 4,
                [`& .${paginationClasses.ul}`]: {
                  justifyContent: 'center',
                },
              }}
            />
          )} */}
        </Stack>
      )}
    </>
  );
}

SpecialSaleProductComp.propTypes = {
  products: PropTypes.any,
  loading: PropTypes.any,
};
