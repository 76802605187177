import { useLocales } from 'src/locales';

const ROOTS = {
  MY_ACCOUNT: 'my-account',
  Legal: 'legal',
};

// const { currentLang } = useLocales();
// const pathPrefix = currentLang.value === 'ar' ? '/ar/' : '/';
// const pathPrefix = window.location.pathname === '/ar' ? '/ar/' : '/';

const usePathPrefix = () => {
  const { currentLang } = useLocales();
  return currentLang.value === 'ar' ? '/ar/' : '/';
};

export const usePaths = () => {
  const pathPrefix = usePathPrefix();

  return {
    // static
    contactUs: `${pathPrefix}contact-us`,
    faq: `${pathPrefix}faq`,
    legal: {
      returnPolicy: `${pathPrefix}return-policy`,
      termsAndConditions: `${pathPrefix}terms-and-conditions`,
      privacyPolicy: `${pathPrefix}privacy-policy`,
      shippingPolicy: `${pathPrefix}shipping-policy`,
      faq: `${pathPrefix}faq`,
    },

    comingSoon: `${pathPrefix}coming-soon`,
    maintenance: `${pathPrefix}maintenance`,
    about: `${pathPrefix}about-us`,
    contact: `${pathPrefix}contact-us`,
    faqs: `${pathPrefix}faqs`,

    page403: `${pathPrefix}error/403`,
    page404: `${pathPrefix}error/404`,
    page500: `${pathPrefix}error/500`,
    offline: `${pathPrefix}error/offline`,

    public: {
      home: `${pathPrefix}`,
      login: `${pathPrefix}login`,
      products: `${pathPrefix}products`,
      productView: (slug) => `${pathPrefix}product/${slug}`,

      categoryView: (slug) => `${pathPrefix}category/${slug?.toLowerCase()}`,

      brands: `${pathPrefix}brands`,
      brandView: (slug) => `${pathPrefix}brand/${slug?.toLowerCase()}`,

      collections: `${pathPrefix}collections`,
      collectionView: (slug) => `${pathPrefix}collection/${slug?.toLowerCase()}`,

      pages: `${pathPrefix}pages`,
      pageView: (slug) => `${pathPrefix}page/${slug}`,

      cart: `${pathPrefix}cart`,
      wishlist: `${pathPrefix}wishlist`,
      blogs: `${pathPrefix}blogs`,
      blogView: (slug) => `${pathPrefix}blogs/${slug}`,

      search: `${pathPrefix}search`,
      searchResults: (query) => `${pathPrefix}search?query=${query}`,
      reviews: `${pathPrefix}reviews`,
      review_view: (slug) => `${pathPrefix}reviews/${slug}`,
    },

    private: {
      order_success: (slug) => `${pathPrefix}order-success/${slug}`,
      order_redirect: `${pathPrefix}order-redirect`,
      order_fail: `${pathPrefix}order-fail`,
      checkout: `${pathPrefix}checkout`,
      my_account: {
        root: `${pathPrefix}${ROOTS.MY_ACCOUNT}`,
        orderView: (slug) => `${pathPrefix}${ROOTS.MY_ACCOUNT}/orders/${slug}`,
      },
    },
  };
};
